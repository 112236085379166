@tailwind base;
@tailwind components;
@tailwind utilities;
@import 'flowbite';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Additional CSS for slider styling */
.slider {
  flex-grow: 1;
  height: 2px; /* Reduced height for subtlety */
  background-color: #ddd;
  border-radius: 9999px;
  appearance: none;
  cursor: pointer;
}

.slider::-webkit-slider-thumb {
  width: 16px; /* Smaller thumb for a finer control */
  height: 16px;
  background-color: #666;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  -webkit-appearance: none;
}

.slider:hover::-webkit-slider-thumb {
  background-color: #444;
}

@media (max-width: 640px) {
  .flex.items-center {
    flex-direction: column;
    align-items: flex-start;
  }
  .slider {
    width: 100%; /* Full width on smaller screens */
  }
}

/* styles.css */
.navbar-bg {
  background-image: 
    linear-gradient(to bottom, rgba(255,255,255,1) 0%, rgba(0,0,0,0) 100%), 
    url('../public/mountains.png');
  background-size: cover;
  background-position: bottom;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.uploading-indicator {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 3px solid rgba(0,123,255, 0.2);
  border-radius: 50%;
  border-top-color: #007bff;
  animation: spin 1s linear infinite;
}

.uploading-text {
  display: inline-block;
  margin-left: 10px;
  color: #007bff;
  font-weight: bold;
}

@keyframes stripe {
  0% {
    background-position: 0% 0;
  }
  100% {
    background-position: 100% 0;
  }
}

.loading-indicator {
  width: 100%;
  height: 10px;
  background-color: #eee;
  background-image: linear-gradient(135deg, #007bff 25%, transparent 25%, transparent 50%, #007bff 50%, #007bff 75%, transparent 75%, transparent);
  background-size: 28px 28px;
  animation: stripe 20s linear infinite;
}

